/**
 * File generated by js-routes 2.2.5
 * Based on Rails 7.0.8 routes of Planner::Application
 */
// eslint-disable-next-line
const __jsr = (
// eslint-disable-next-line
() => {
    const hasProp = (value, key) => Object.prototype.hasOwnProperty.call(value, key);
    let NodeTypes;
    (function (NodeTypes) {
        NodeTypes[NodeTypes["GROUP"] = 1] = "GROUP";
        NodeTypes[NodeTypes["CAT"] = 2] = "CAT";
        NodeTypes[NodeTypes["SYMBOL"] = 3] = "SYMBOL";
        NodeTypes[NodeTypes["OR"] = 4] = "OR";
        NodeTypes[NodeTypes["STAR"] = 5] = "STAR";
        NodeTypes[NodeTypes["LITERAL"] = 6] = "LITERAL";
        NodeTypes[NodeTypes["SLASH"] = 7] = "SLASH";
        NodeTypes[NodeTypes["DOT"] = 8] = "DOT";
    })(NodeTypes || (NodeTypes = {}));
    const isBrowser = typeof window !== "undefined";
    const ModuleReferences = {
        CJS: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                module.exports = routes;
            },
            isSupported() {
                return typeof module === "object";
            },
        },
        AMD: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                define([], function () {
                    return routes;
                });
            },
            isSupported() {
                return typeof define === "function" && !!define.amd;
            },
        },
        UMD: {
            define(routes) {
                if (ModuleReferences.AMD.isSupported()) {
                    ModuleReferences.AMD.define(routes);
                }
                else {
                    if (ModuleReferences.CJS.isSupported()) {
                        try {
                            ModuleReferences.CJS.define(routes);
                        }
                        catch (error) {
                            if (error.name !== "TypeError")
                                throw error;
                        }
                    }
                }
            },
            isSupported() {
                return (ModuleReferences.AMD.isSupported() ||
                    ModuleReferences.CJS.isSupported());
            },
        },
        ESM: {
            define() {
                // Module can only be defined using ruby code generation
            },
            isSupported() {
                // Its impossible to check if "export" keyword is supported
                return true;
            },
        },
        NIL: {
            define() {
                // Defined using const __jsr = 
            },
            isSupported() {
                return true;
            },
        },
        DTS: {
            // Acts the same as ESM
            define(routes) {
                ModuleReferences.ESM.define(routes);
            },
            isSupported() {
                return ModuleReferences.ESM.isSupported();
            },
        },
    };
    class ParametersMissing extends Error {
        constructor(...keys) {
            super(`Route missing required keys: ${keys.join(", ")}`);
            this.keys = keys;
            Object.setPrototypeOf(this, Object.getPrototypeOf(this));
            this.name = ParametersMissing.name;
        }
    }
    const UriEncoderSegmentRegex = /[^a-zA-Z0-9\-._~!$&'()*+,;=:@]/g;
    const ReservedOptions = [
        "anchor",
        "trailing_slash",
        "subdomain",
        "host",
        "port",
        "protocol",
    ];
    class UtilsClass {
        constructor() {
            this.configuration = {
                prefix: "",
                default_url_options: {},
                special_options_key: "_options",
                serializer: null || this.default_serializer.bind(this),
            };
        }
        default_serializer(value, prefix) {
            if (this.is_nullable(value)) {
                return "";
            }
            if (!prefix && !this.is_object(value)) {
                throw new Error("Url parameters should be a javascript hash");
            }
            prefix = prefix || "";
            const result = [];
            if (this.is_array(value)) {
                for (const element of value) {
                    result.push(this.default_serializer(element, prefix + "[]"));
                }
            }
            else if (this.is_object(value)) {
                for (let key in value) {
                    if (!hasProp(value, key))
                        continue;
                    let prop = value[key];
                    if (this.is_nullable(prop) && prefix) {
                        prop = "";
                    }
                    if (this.is_not_nullable(prop)) {
                        if (prefix) {
                            key = prefix + "[" + key + "]";
                        }
                        result.push(this.default_serializer(prop, key));
                    }
                }
            }
            else {
                if (this.is_not_nullable(value)) {
                    result.push(encodeURIComponent(prefix) + "=" + encodeURIComponent("" + value));
                }
            }
            return result.join("&");
        }
        serialize(object) {
            return this.configuration.serializer(object);
        }
        extract_options(number_of_params, args) {
            const last_el = args[args.length - 1];
            if ((args.length > number_of_params && last_el === 0) ||
                (this.is_object(last_el) &&
                    !this.looks_like_serialized_model(last_el))) {
                if (this.is_object(last_el)) {
                    delete last_el[this.configuration.special_options_key];
                }
                return {
                    args: args.slice(0, args.length - 1),
                    options: last_el,
                };
            }
            else {
                return { args, options: {} };
            }
        }
        looks_like_serialized_model(object) {
            return (this.is_object(object) &&
                !(this.configuration.special_options_key in object) &&
                ("id" in object || "to_param" in object || "toParam" in object));
        }
        path_identifier(object) {
            const result = this.unwrap_path_identifier(object);
            return this.is_nullable(result) ||
                (false &&
                    result === false)
                ? ""
                : "" + result;
        }
        unwrap_path_identifier(object) {
            let result = object;
            if (!this.is_object(object)) {
                return object;
            }
            if ("to_param" in object) {
                result = object.to_param;
            }
            else if ("toParam" in object) {
                result = object.toParam;
            }
            else if ("id" in object) {
                result = object.id;
            }
            else {
                result = object;
            }
            return this.is_callable(result) ? result.call(object) : result;
        }
        partition_parameters(parts, required_params, default_options, call_arguments) {
            // eslint-disable-next-line prefer-const
            let { args, options } = this.extract_options(parts.length, call_arguments);
            if (args.length > parts.length) {
                throw new Error("Too many parameters provided for path");
            }
            let use_all_parts = args.length > required_params.length;
            const parts_options = {
                ...this.configuration.default_url_options,
            };
            for (const key in options) {
                const value = options[key];
                if (!hasProp(options, key))
                    continue;
                use_all_parts = true;
                if (parts.includes(key)) {
                    parts_options[key] = value;
                }
            }
            options = {
                ...this.configuration.default_url_options,
                ...default_options,
                ...options,
            };
            const keyword_parameters = {};
            let query_parameters = {};
            for (const key in options) {
                if (!hasProp(options, key))
                    continue;
                const value = options[key];
                if (key === "params") {
                    if (this.is_object(value)) {
                        query_parameters = {
                            ...query_parameters,
                            ...value,
                        };
                    }
                    else {
                        throw new Error("params value should always be an object");
                    }
                }
                else if (this.is_reserved_option(key)) {
                    keyword_parameters[key] = value;
                }
                else {
                    if (!this.is_nullable(value) &&
                        (value !== default_options[key] || required_params.includes(key))) {
                        query_parameters[key] = value;
                    }
                }
            }
            const route_parts = use_all_parts ? parts : required_params;
            let i = 0;
            for (const part of route_parts) {
                if (i < args.length) {
                    const value = args[i];
                    if (!hasProp(parts_options, part)) {
                        query_parameters[part] = value;
                        ++i;
                    }
                }
            }
            return { keyword_parameters, query_parameters };
        }
        build_route(parts, required_params, default_options, route, absolute, args) {
            const { keyword_parameters, query_parameters } = this.partition_parameters(parts, required_params, default_options, args);
            const missing_params = required_params.filter((param) => !hasProp(query_parameters, param) ||
                this.is_nullable(query_parameters[param]));
            if (missing_params.length) {
                throw new ParametersMissing(...missing_params);
            }
            let result = this.get_prefix() + this.visit(route, query_parameters);
            if (keyword_parameters.trailing_slash) {
                result = result.replace(/(.*?)[/]?$/, "$1/");
            }
            const url_params = this.serialize(query_parameters);
            if (url_params.length) {
                result += "?" + url_params;
            }
            result += keyword_parameters.anchor
                ? "#" + keyword_parameters.anchor
                : "";
            if (absolute) {
                result = this.route_url(keyword_parameters) + result;
            }
            return result;
        }
        visit(route, parameters, optional = false) {
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return this.visit(route[1], parameters, true);
                case NodeTypes.CAT:
                    return this.visit_cat(route, parameters, optional);
                case NodeTypes.SYMBOL:
                    return this.visit_symbol(route, parameters, optional);
                case NodeTypes.STAR:
                    return this.visit_globbing(route[1], parameters, true);
                case NodeTypes.LITERAL:
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        is_not_nullable(object) {
            return !this.is_nullable(object);
        }
        is_nullable(object) {
            return object === undefined || object === null;
        }
        visit_cat(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, left, right], parameters, optional) {
            const left_part = this.visit(left, parameters, optional);
            let right_part = this.visit(right, parameters, optional);
            if (optional &&
                ((this.is_optional_node(left[0]) && !left_part) ||
                    (this.is_optional_node(right[0]) && !right_part))) {
                return "";
            }
            // if left_part ends on '/' and right_part starts on '/'
            if (left_part[left_part.length - 1] === "/" && right_part[0] === "/") {
                // strip slash from right_part
                // to prevent double slash
                right_part = right_part.substring(1);
            }
            return left_part + right_part;
        }
        visit_symbol(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, key], parameters, optional) {
            const value = this.path_identifier(parameters[key]);
            delete parameters[key];
            if (value.length) {
                return this.encode_segment(value);
            }
            if (optional) {
                return "";
            }
            else {
                throw new ParametersMissing(key);
            }
        }
        encode_segment(segment) {
            return segment.replace(UriEncoderSegmentRegex, (str) => encodeURIComponent(str));
        }
        is_optional_node(node) {
            return [NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT].includes(node);
        }
        build_path_spec(route, wildcard = false) {
            let key;
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return "(" + this.build_path_spec(route[1]) + ")";
                case NodeTypes.CAT:
                    return (this.build_path_spec(route[1]) + this.build_path_spec(route[2]));
                case NodeTypes.STAR:
                    return this.build_path_spec(route[1], true);
                case NodeTypes.SYMBOL:
                    key = route[1];
                    if (wildcard) {
                        return (key.startsWith("*") ? "" : "*") + key;
                    }
                    else {
                        return ":" + key;
                    }
                    break;
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                case NodeTypes.LITERAL:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        visit_globbing(route, parameters, optional) {
            const key = route[1];
            let value = parameters[key];
            delete parameters[key];
            if (this.is_nullable(value)) {
                return this.visit(route, parameters, optional);
            }
            if (this.is_array(value)) {
                value = value.join("/");
            }
            const result = this.path_identifier(value);
            return false
                ? result
                : encodeURI(result);
        }
        get_prefix() {
            const prefix = this.configuration.prefix;
            return prefix.match("/$")
                ? prefix.substring(0, prefix.length - 1)
                : prefix;
        }
        route(parts_table, route_spec, absolute = false) {
            const required_params = [];
            const parts = [];
            const default_options = {};
            for (const [part, { r: required, d: value }] of Object.entries(parts_table)) {
                parts.push(part);
                if (required) {
                    required_params.push(part);
                }
                if (this.is_not_nullable(value)) {
                    default_options[part] = value;
                }
            }
            const result = (...args) => {
                return this.build_route(parts, required_params, default_options, route_spec, absolute, args);
            };
            result.requiredParams = () => required_params;
            result.toString = () => {
                return this.build_path_spec(route_spec);
            };
            return result;
        }
        route_url(route_defaults) {
            const hostname = route_defaults.host || this.current_host();
            if (!hostname) {
                return "";
            }
            const subdomain = route_defaults.subdomain
                ? route_defaults.subdomain + "."
                : "";
            const protocol = route_defaults.protocol || this.current_protocol();
            let port = route_defaults.port ||
                (!route_defaults.host ? this.current_port() : undefined);
            port = port ? ":" + port : "";
            return protocol + "://" + subdomain + hostname + port;
        }
        current_host() {
            var _a;
            return (isBrowser && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname)) || "";
        }
        current_protocol() {
            var _a, _b;
            return ((isBrowser && ((_b = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.protocol) === null || _b === void 0 ? void 0 : _b.replace(/:$/, ""))) || "http");
        }
        current_port() {
            var _a;
            return (isBrowser && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.port)) || "";
        }
        is_object(value) {
            return (typeof value === "object" &&
                Object.prototype.toString.call(value) === "[object Object]");
        }
        is_array(object) {
            return object instanceof Array;
        }
        is_callable(object) {
            return typeof object === "function" && !!object.call;
        }
        is_reserved_option(key) {
            return ReservedOptions.includes(key);
        }
        configure(new_config) {
            this.configuration = { ...this.configuration, ...new_config };
            return this.configuration;
        }
        config() {
            return { ...this.configuration };
        }
        is_module_supported(name) {
            return ModuleReferences[name].isSupported();
        }
        ensure_module_supported(name) {
            if (!this.is_module_supported(name)) {
                throw new Error(`${name} is not supported by runtime`);
            }
        }
        define_module(name, module) {
            this.ensure_module_supported(name);
            ModuleReferences[name].define(module);
        }
    }
    const Utils = new UtilsClass();
    // We want this helper name to be short
    const __jsr = {
        r(parts_table, route_spec, absolute) {
            return Utils.route(parts_table, route_spec, absolute);
        },
    };
    const result = {
        ...__jsr,
        configure: (config) => {
            return Utils.configure(config);
        },
        config: () => {
            return Utils.config();
        },
        serialize: (object) => {
            return Utils.serialize(object);
        },
        ...{},
    };
    Utils.define_module("ESM", result);
    return result;
})();
export const configure = __jsr.configure;

export const config = __jsr.config;

export const serialize = __jsr.serialize;

/**
 * Generates rails route to
 * /customer/additional_info/about_adviser(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const about_adviser_customer_additional_info_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"additional_info"],[2,[7,"/"],[2,[6,"about_adviser"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/invitation/accept(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const accept_user_invitation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"invitation"],[2,[7,"/"],[2,[6,"accept"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/brokerage_accounts/positions/:id/additional(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const additional_adviser_customer_brokerage_accounts_position_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"brokerage_accounts"],[2,[7,"/"],[2,[6,"positions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"additional"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/brokerage_accounts/positions/:id/additional(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const additional_customer_brokerage_accounts_position_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"brokerage_accounts"],[2,[7,"/"],[2,[6,"positions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"additional"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/logs/action_histories(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_action_histories_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"logs"],[2,[7,"/"],[2,[6,"action_histories"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/logs/action_histories/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_action_history_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"logs"],[2,[7,"/"],[2,[6,"action_histories"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/action_logs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_action_log_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"action_logs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/action_logs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_action_logs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"action_logs"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/settings/auto_contract_templates/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auto_contract_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"auto_contract_templates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/settings/auto_contract_templates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auto_contract_templates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"auto_contract_templates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/become_as/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_become_as_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"become_as"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/bug_reports/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_bug_report_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"bug_reports"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/bug_reports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_bug_reports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"bug_reports"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/settings/document_builder_templates/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_document_builder_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"document_builder_templates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/settings/document_builder_templates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_document_builder_templates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"document_builder_templates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin_global/cbr/complaints/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_global_cbr_complaint_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin_global"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"complaints"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin_global/cbr/complaints(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_global_cbr_complaints_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin_global"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"complaints"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin_global/cbr/doc_types/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_global_cbr_doc_type_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin_global"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"doc_types"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin_global/cbr/doc_types(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_global_cbr_doc_types_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin_global"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"doc_types"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin_global/cbr(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_global_cbr_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin_global"],[2,[7,"/"],[2,[6,"cbr"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin_global/cbr/report_belonging_groups/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_global_cbr_report_belonging_group_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin_global"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"report_belonging_groups"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin_global/cbr/report_belonging_groups(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_global_cbr_report_belonging_groups_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin_global"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"report_belonging_groups"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin_global/cbr/report_countries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_global_cbr_report_countries_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin_global"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"report_countries"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin_global/cbr/report_countries/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_global_cbr_report_country_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin_global"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"report_countries"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin_global/cbr/report_documents/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_global_cbr_report_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin_global"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"report_documents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin_global/cbr/report_documents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_global_cbr_report_documents_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin_global"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"report_documents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin_global/cbr/report_regions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_global_cbr_report_region_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin_global"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"report_regions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin_global/cbr/report_regions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_global_cbr_report_regions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin_global"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"report_regions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin_global/intelinvest_profiles(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_global_intelinvest_profiles_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin_global"],[2,[7,"/"],[2,[6,"intelinvest_profiles"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin_global/jobs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_global_job_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin_global"],[2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin_global/jobs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_global_jobs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin_global"],[2,[7,"/"],[2,[6,"jobs"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin_global/request_logs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_global_request_logs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin_global"],[2,[7,"/"],[2,[6,"request_logs"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin_global/shared/asset_payments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_global_shared_asset_payment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin_global"],[2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"asset_payments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin_global/shared/asset_payments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_global_shared_asset_payments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin_global"],[2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"asset_payments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin_global/shared/brokerage_order_errors/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_global_shared_brokerage_order_error_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin_global"],[2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"brokerage_order_errors"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin_global/shared/brokerage_order_errors(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_global_shared_brokerage_order_errors_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin_global"],[2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"brokerage_order_errors"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin_global/shared/exchange_schedules/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_global_shared_exchange_schedule_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin_global"],[2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"exchange_schedules"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin_global/shared/exchange_schedules(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_global_shared_exchange_schedules_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin_global"],[2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"exchange_schedules"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin_global/shared/industries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_global_shared_industries_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin_global"],[2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"industries"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin_global/shared/instruments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_global_shared_instruments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin_global"],[2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"instruments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin_global/shared/issuers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_global_shared_issuers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin_global"],[2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"issuers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin_global/shared/splits(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_global_shared_splits_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin_global"],[2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"splits"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin_global/statistic/cbr(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_global_statistic_cbr_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin_global"],[2,[7,"/"],[2,[6,"statistic"],[2,[7,"/"],[2,[6,"cbr"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin_global/tenants(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_global_tenants_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin_global"],[2,[7,"/"],[2,[6,"tenants"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/settings/legal_documents/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_legal_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"legal_documents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/settings/legal_documents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_legal_documents_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"legal_documents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/settings/lock_histories(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_lock_histories_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"lock_histories"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/panels(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_panels_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"panels"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_root_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/settings/shared_settings/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_setting_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"shared_settings"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/settings/shared_settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_settings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"shared_settings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/logs/smses(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_smses_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"logs"],[2,[7,"/"],[2,[6,"smses"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/acceptances(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_acceptances_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"acceptances"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/amocrm(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_amocrm_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"amocrm"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/anketas/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_anketa_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"anketas"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/anketas/:anketa_id/questions/:id(.:format)
 * @param {any} anketa_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_anketa_question_path = __jsr.r({"anketa_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"anketas"],[2,[7,"/"],[2,[3,"anketa_id"],[2,[7,"/"],[2,[6,"questions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/anketas/:anketa_id/questions(.:format)
 * @param {any} anketa_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_anketa_questions_path = __jsr.r({"anketa_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"anketas"],[2,[7,"/"],[2,[3,"anketa_id"],[2,[7,"/"],[2,[6,"questions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/anketas/settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_anketa_settings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"anketas"],[2,[7,"/"],[2,[6,"settings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/anketas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_anketas_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"anketas"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/appeals/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_appeal_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"appeals"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/appeals/:appeal_id/response(.:format)
 * @param {any} appeal_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_appeal_response_path = __jsr.r({"appeal_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"appeals"],[2,[7,"/"],[2,[3,"appeal_id"],[2,[7,"/"],[2,[6,"response"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/appeals(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_appeals_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"appeals"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/auto_contract_templates/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_auto_contract_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"auto_contract_templates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/auto_contract_templates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_auto_contract_templates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"auto_contract_templates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/become_as/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_become_as_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"become_as"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/bug_reports/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_bug_report_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"bug_reports"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/bug_reports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_bug_reports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"bug_reports"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_cbr_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/affiliation_persons/entities(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_cbr_affiliation_persons_entities_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"affiliation_persons"],[2,[7,"/"],[2,[6,"entities"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/affiliation_persons/entities/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_cbr_affiliation_persons_entity_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"affiliation_persons"],[2,[7,"/"],[2,[6,"entities"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/affiliation_persons/individuals/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_cbr_affiliation_persons_individual_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"affiliation_persons"],[2,[7,"/"],[2,[6,"individuals"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/affiliation_persons/individuals(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_cbr_affiliation_persons_individuals_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"affiliation_persons"],[2,[7,"/"],[2,[6,"individuals"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/affiliation_properties(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_cbr_affiliation_properties_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"affiliation_properties"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/affiliation_properties/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_cbr_affiliation_property_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"affiliation_properties"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/assistant_accountant(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_cbr_assistant_accountant_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"assistant_accountant"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/audit_manager(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_cbr_audit_manager_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"audit_manager"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/belonging_person_groups/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_cbr_belonging_person_group_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"belonging_person_groups"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/belonging_person_groups(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_cbr_belonging_person_groups_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"belonging_person_groups"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/board_directors_chairman(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_cbr_board_directors_chairman_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"board_directors_chairman"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/board_directors_members/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_cbr_board_directors_member_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"board_directors_members"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/board_directors_members(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_cbr_board_directors_members_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"board_directors_members"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/collegial_executives/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_cbr_collegial_executive_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"collegial_executives"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/collegial_executives(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_cbr_collegial_executives_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"collegial_executives"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/customers_complaints/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_cbr_customers_complaint_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"customers_complaints"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/customers_complaints(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_cbr_customers_complaints_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"customers_complaints"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/division_accountants/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_cbr_division_accountant_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"division_accountants"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/division_accountants(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_cbr_division_accountants_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"division_accountants"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/division_heads/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_cbr_division_head_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"division_heads"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/division_heads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_cbr_division_heads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"division_heads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/employee_details(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_cbr_employee_details_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"employee_details"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/general_accountant(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_cbr_general_accountant_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"general_accountant"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/individual_info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_cbr_individual_info_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"individual_info"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/internal_control_manager(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_cbr_internal_control_manager_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"internal_control_manager"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/manipulate_control_head(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_cbr_manipulate_control_head_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"manipulate_control_head"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/month_activities(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_cbr_month_activities_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"month_activities"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/month_activities/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_cbr_month_activity_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"month_activities"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/org_info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_cbr_org_info_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"org_info"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/reports/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_cbr_report_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/reporting_entity_controllers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_cbr_reporting_entity_controller_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"reporting_entity_controllers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/reporting_entity_controllers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_cbr_reporting_entity_controllers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"reporting_entity_controllers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/reporting_entity_info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_cbr_reporting_entity_info_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"reporting_entity_info"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/reporting_form_persons/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_cbr_reporting_form_person_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"reporting_form_persons"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/reporting_form_persons(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_cbr_reporting_form_persons_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"reporting_form_persons"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/reports/irregual(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_cbr_reports_irregual_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"irregual"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/reports/month10(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_cbr_reports_month10_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"month10"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/reports/quarterly10(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_cbr_reports_quarterly10_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"quarterly10"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/reports/quarterly30(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_cbr_reports_quarterly30_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"quarterly30"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/reports/year10(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_cbr_reports_year10_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"year10"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/reports/year30(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_cbr_reports_year30_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"year30"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/reports/year45(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_cbr_reports_year45_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"year45"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/risk_manager(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_cbr_risk_manager_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"risk_manager"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/separate_divisions_infos/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_cbr_separate_divisions_info_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"separate_divisions_infos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/separate_divisions_infos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_cbr_separate_divisions_infos_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"separate_divisions_infos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/sole_executive(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_cbr_sole_executive_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"sole_executive"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/sole_executive_assistant(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_cbr_sole_executive_assistant_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"sole_executive_assistant"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/structual_unit_head(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_cbr_structual_unit_head_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"structual_unit_head"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/chats/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_chat_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"chats"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/chats/:chat_id/messages(.:format)
 * @param {any} chat_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_chat_messages_path = __jsr.r({"chat_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"chats"],[2,[7,"/"],[2,[3,"chat_id"],[2,[7,"/"],[2,[6,"messages"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/chats(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_chats_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"chats"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /adviser/contracts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_contract_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/contracts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_contracts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"contracts"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/services/custom/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_custom_service_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[6,"custom"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/services/custom/:custom_service_id/wizard/:id(.:format)
 * @param {any} custom_service_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_custom_service_custom_wizard_path = __jsr.r({"custom_service_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[6,"custom"],[2,[7,"/"],[2,[3,"custom_service_id"],[2,[7,"/"],[2,[6,"wizard"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/services/custom(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_custom_services_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[6,"custom"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_customer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/brokerage_accounts/analytics(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_customer_brokerage_accounts_analytics_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"brokerage_accounts"],[2,[7,"/"],[2,[6,"analytics"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/brokerage_accounts/brokerage_orders(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_customer_brokerage_accounts_brokerage_orders_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"brokerage_accounts"],[2,[7,"/"],[2,[6,"brokerage_orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/brokerage_accounts/events(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_customer_brokerage_accounts_events_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"brokerage_accounts"],[2,[7,"/"],[2,[6,"events"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/brokerage_accounts/investment_recommendations/:id(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_customer_brokerage_accounts_investment_recommendation_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"brokerage_accounts"],[2,[7,"/"],[2,[6,"investment_recommendations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/brokerage_accounts/investment_recommendations/:investment_recommendation_id/investment_instruments/:id(.:format)
 * @param {any} customer_id
 * @param {any} investment_recommendation_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_customer_brokerage_accounts_investment_recommendation_investment_instrument_path = __jsr.r({"customer_id":{"r":true},"investment_recommendation_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"brokerage_accounts"],[2,[7,"/"],[2,[6,"investment_recommendations"],[2,[7,"/"],[2,[3,"investment_recommendation_id"],[2,[7,"/"],[2,[6,"investment_instruments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/brokerage_accounts/investment_recommendations/:investment_recommendation_id/investment_instruments(.:format)
 * @param {any} customer_id
 * @param {any} investment_recommendation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_customer_brokerage_accounts_investment_recommendation_investment_instruments_path = __jsr.r({"customer_id":{"r":true},"investment_recommendation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"brokerage_accounts"],[2,[7,"/"],[2,[6,"investment_recommendations"],[2,[7,"/"],[2,[3,"investment_recommendation_id"],[2,[7,"/"],[2,[6,"investment_instruments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/brokerage_accounts/investment_recommendations(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_customer_brokerage_accounts_investment_recommendations_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"brokerage_accounts"],[2,[7,"/"],[2,[6,"investment_recommendations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/brokerage_accounts/operations(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_customer_brokerage_accounts_operations_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"brokerage_accounts"],[2,[7,"/"],[2,[6,"operations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/brokerage_accounts/positions(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_customer_brokerage_accounts_positions_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"brokerage_accounts"],[2,[7,"/"],[2,[6,"positions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/chats(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_customer_chats_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"chats"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/contracts/:id(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_customer_contract_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/contracts(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_customer_contracts_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"contracts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/credentials/:id(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_customer_credential_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"credentials"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/credentials(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_customer_credentials_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"credentials"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/events(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_customer_events_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"events"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/finance/plans/:id(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_customer_finance_plan_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"finance"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/finance/plans/:plan_id/goals/:id(.:format)
 * @param {any} customer_id
 * @param {any} plan_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_customer_finance_plan_goal_path = __jsr.r({"customer_id":{"r":true},"plan_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"finance"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"plan_id"],[2,[7,"/"],[2,[6,"goals"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/finance/plans/:plan_id/goals(.:format)
 * @param {any} customer_id
 * @param {any} plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_customer_finance_plan_goals_path = __jsr.r({"customer_id":{"r":true},"plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"finance"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"plan_id"],[2,[7,"/"],[2,[6,"goals"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/finance/plans/:plan_id/recommendations/:id(.:format)
 * @param {any} customer_id
 * @param {any} plan_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_customer_finance_plan_recommendation_path = __jsr.r({"customer_id":{"r":true},"plan_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"finance"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"plan_id"],[2,[7,"/"],[2,[6,"recommendations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/finance/plans/:plan_id/recommendations(.:format)
 * @param {any} customer_id
 * @param {any} plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_customer_finance_plan_recommendations_path = __jsr.r({"customer_id":{"r":true},"plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"finance"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"plan_id"],[2,[7,"/"],[2,[6,"recommendations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/finance/plans/:plan_id/repayment_strategy(.:format)
 * @param {any} customer_id
 * @param {any} plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_customer_finance_plan_repayment_strategy_path = __jsr.r({"customer_id":{"r":true},"plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"finance"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"plan_id"],[2,[7,"/"],[2,[6,"repayment_strategy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/finance/plans/:plan_id/risks/:id(.:format)
 * @param {any} customer_id
 * @param {any} plan_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_customer_finance_plan_risk_path = __jsr.r({"customer_id":{"r":true},"plan_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"finance"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"plan_id"],[2,[7,"/"],[2,[6,"risks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/finance/plans/:plan_id/risks(.:format)
 * @param {any} customer_id
 * @param {any} plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_customer_finance_plan_risks_path = __jsr.r({"customer_id":{"r":true},"plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"finance"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"plan_id"],[2,[7,"/"],[2,[6,"risks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/finance/plans(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_customer_finance_plans_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"finance"],[2,[7,"/"],[2,[6,"plans"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/investment_recommendations/:id(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_customer_investment_recommendation_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"investment_recommendations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/investment_recommendations/:investment_recommendation_id/investment_instruments/:id(.:format)
 * @param {any} customer_id
 * @param {any} investment_recommendation_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_customer_investment_recommendation_investment_instrument_path = __jsr.r({"customer_id":{"r":true},"investment_recommendation_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"investment_recommendations"],[2,[7,"/"],[2,[3,"investment_recommendation_id"],[2,[7,"/"],[2,[6,"investment_instruments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/investment_recommendations/:investment_recommendation_id/investment_instruments(.:format)
 * @param {any} customer_id
 * @param {any} investment_recommendation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_customer_investment_recommendation_investment_instruments_path = __jsr.r({"customer_id":{"r":true},"investment_recommendation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"investment_recommendations"],[2,[7,"/"],[2,[3,"investment_recommendation_id"],[2,[7,"/"],[2,[6,"investment_instruments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/investment_recommendations(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_customer_investment_recommendations_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"investment_recommendations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/model_portfolios/:id(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_customer_model_portfolio_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"model_portfolios"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/model_portfolios(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_customer_model_portfolios_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"model_portfolios"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/notes/:id(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_customer_note_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"notes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/notes(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_customer_notes_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"notes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/payments(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_customer_payments_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"payments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/portfolio(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_customer_portfolio_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"portfolio"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/portfolios(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_customer_portfolios_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"portfolios"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/purchases(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_customer_purchases_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"purchases"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/user_risk_profiles/:id(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_customer_user_risk_profile_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"user_risk_profiles"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/user_risk_profiles(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_customer_user_risk_profiles_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"user_risk_profiles"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_customers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/customers_archives(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_customers_archives_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"customers_archives"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/documents/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/document_builder_templates/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_document_builder_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"document_builder_templates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/document_builder_templates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_document_builder_templates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"document_builder_templates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/documents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_documents_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"documents"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /adviser/events/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_event_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"events"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/events(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_events_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"events"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /adviser/events/preferences(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_events_preferences_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"events"],[2,[7,"/"],[2,[6,"preferences"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/events/shares(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_events_shares_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"events"],[2,[7,"/"],[2,[6,"shares"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/finance_plans/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_finance_plan_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"finance_plans"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/finance_plans(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_finance_plans_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"finance_plans"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /adviser/investment_instruments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_investment_instruments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"investment_instruments"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /adviser/investment_recommendations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_investment_recommendation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"investment_recommendations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/investment_recommendations/:investment_recommendation_id/investment_instruments/:id(.:format)
 * @param {any} investment_recommendation_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_investment_recommendation_investment_instrument_path = __jsr.r({"investment_recommendation_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"investment_recommendations"],[2,[7,"/"],[2,[3,"investment_recommendation_id"],[2,[7,"/"],[2,[6,"investment_instruments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/investment_recommendations/:investment_recommendation_id/investment_instruments(.:format)
 * @param {any} investment_recommendation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_investment_recommendation_investment_instruments_path = __jsr.r({"investment_recommendation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"investment_recommendations"],[2,[7,"/"],[2,[3,"investment_recommendation_id"],[2,[7,"/"],[2,[6,"investment_instruments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/investment_recommendations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_investment_recommendations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"investment_recommendations"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/legal_documents/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_legal_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"legal_documents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/legal_documents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_legal_documents_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"legal_documents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/mailings/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_mailing_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"mailings"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/mailings/:mailing_id/recipients(.:format)
 * @param {any} mailing_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_mailing_recipients_path = __jsr.r({"mailing_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"mailings"],[2,[7,"/"],[2,[3,"mailing_id"],[2,[7,"/"],[2,[6,"recipients"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/mailings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_mailings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"mailings"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /adviser/model_portfolios/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_model_portfolio_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"model_portfolios"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/model_portfolios/:model_portfolio_id/analytics(.:format)
 * @param {any} model_portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_model_portfolio_analytics_path = __jsr.r({"model_portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"model_portfolios"],[2,[7,"/"],[2,[3,"model_portfolio_id"],[2,[7,"/"],[2,[6,"analytics"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/model_portfolios/:model_portfolio_id/events(.:format)
 * @param {any} model_portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_model_portfolio_events_path = __jsr.r({"model_portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"model_portfolios"],[2,[7,"/"],[2,[3,"model_portfolio_id"],[2,[7,"/"],[2,[6,"events"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/model_portfolios/:model_portfolio_id/instances(.:format)
 * @param {any} model_portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_model_portfolio_instances_path = __jsr.r({"model_portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"model_portfolios"],[2,[7,"/"],[2,[3,"model_portfolio_id"],[2,[7,"/"],[2,[6,"instances"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/model_portfolios/:model_portfolio_id/operations/:id(.:format)
 * @param {any} model_portfolio_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_model_portfolio_operation_path = __jsr.r({"model_portfolio_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"model_portfolios"],[2,[7,"/"],[2,[3,"model_portfolio_id"],[2,[7,"/"],[2,[6,"operations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/model_portfolios/:model_portfolio_id/operations(.:format)
 * @param {any} model_portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_model_portfolio_operations_path = __jsr.r({"model_portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"model_portfolios"],[2,[7,"/"],[2,[3,"model_portfolio_id"],[2,[7,"/"],[2,[6,"operations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/model_portfolios/:model_portfolio_id/positions/:id(.:format)
 * @param {any} model_portfolio_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_model_portfolio_position_path = __jsr.r({"model_portfolio_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"model_portfolios"],[2,[7,"/"],[2,[3,"model_portfolio_id"],[2,[7,"/"],[2,[6,"positions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/model_portfolios/:model_portfolio_id/positions(.:format)
 * @param {any} model_portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_model_portfolio_positions_path = __jsr.r({"model_portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"model_portfolios"],[2,[7,"/"],[2,[3,"model_portfolio_id"],[2,[7,"/"],[2,[6,"positions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/model_portfolios(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_model_portfolios_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"model_portfolios"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /adviser/notifications/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_notification_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"notifications"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/notifications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_notifications_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"notifications"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /adviser/partners/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_partner_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"partners"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/partners(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_partners_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"partners"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /adviser/payments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_payment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"payments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/payments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_payments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"payments"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /adviser/portfolios(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_portfolios_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"portfolios"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /adviser/profile(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_profile_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"profile"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/risk_profiles/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_risk_profile_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"risk_profiles"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/risk_profile_setting(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_risk_profile_setting_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"risk_profile_setting"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/risk_profiles(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_risk_profiles_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"risk_profiles"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_root_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /adviser/search/customers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_search_customers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"customers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/services/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_service_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/services/:service_id/cancellation_options(.:format)
 * @param {any} service_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_service_cancellation_options_path = __jsr.r({"service_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[3,"service_id"],[2,[7,"/"],[2,[6,"cancellation_options"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/services/:service_id/faq/:id(.:format)
 * @param {any} service_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_service_faq_path = __jsr.r({"service_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[3,"service_id"],[2,[7,"/"],[2,[6,"faq"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/services/:service_id/faq(.:format)
 * @param {any} service_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_service_faq_index_path = __jsr.r({"service_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[3,"service_id"],[2,[7,"/"],[2,[6,"faq"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/services/:service_id/service_tariffs/:id(.:format)
 * @param {any} service_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_service_service_tariff_path = __jsr.r({"service_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[3,"service_id"],[2,[7,"/"],[2,[6,"service_tariffs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/services/:service_id/service_tariffs(.:format)
 * @param {any} service_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_service_service_tariffs_path = __jsr.r({"service_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[3,"service_id"],[2,[7,"/"],[2,[6,"service_tariffs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/services/:service_id/wizard/:id(.:format)
 * @param {any} service_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_service_wizard_path = __jsr.r({"service_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[3,"service_id"],[2,[7,"/"],[2,[6,"wizard"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/services(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_services_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"services"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/shared_settings/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_setting_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"shared_settings"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/shared_settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_settings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"shared_settings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/smses(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_smses_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"smses"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/statistics/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_statistic_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"statistics"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/statistics(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_statistics_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"statistics"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /adviser/subscriptions/:id(/detail/:detail_id)(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_subscription_path = __jsr.r({"id":{"r":true},"detail_id":{},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[3,"id"],[2,[1,[2,[7,"/"],[2,[6,"detail"],[2,[7,"/"],[3,"detail_id"]]]]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/subscriptions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_subscriptions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"subscriptions"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/trading_platforms/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_trading_platform_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"trading_platforms"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/trading_platforms(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_trading_platforms_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"trading_platforms"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/anketas/settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adviser_update_anketa_settings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"anketas"],[2,[7,"/"],[2,[6,"settings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/form/affiliations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const affiliations_adviser_cbr_form_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"form"],[2,[7,"/"],[2,[6,"affiliations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_credentials(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_credentials_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_credentials"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api_events/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_event_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api_events"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/platform/v1/events(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_platform_v1_events_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"platform"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"events"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/contracts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_contract_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/contracts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_contracts_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"contracts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/customers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_customer_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/customers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_customers_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"customers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/model_portfolios/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_model_portfolio_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"model_portfolios"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/model_portfolios/:model_portfolio_id/operations/:id(.:format)
 * @param {any} model_portfolio_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_model_portfolio_operation_path = __jsr.r({"model_portfolio_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"model_portfolios"],[2,[7,"/"],[2,[3,"model_portfolio_id"],[2,[7,"/"],[2,[6,"operations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/model_portfolios/:model_portfolio_id/operations(.:format)
 * @param {any} model_portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_model_portfolio_operations_path = __jsr.r({"model_portfolio_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"model_portfolios"],[2,[7,"/"],[2,[3,"model_portfolio_id"],[2,[7,"/"],[2,[6,"operations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/model_portfolios/:model_portfolio_id/positions(.:format)
 * @param {any} model_portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_model_portfolio_positions_path = __jsr.r({"model_portfolio_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"model_portfolios"],[2,[7,"/"],[2,[3,"model_portfolio_id"],[2,[7,"/"],[2,[6,"positions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/model_portfolios(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_model_portfolios_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"model_portfolios"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/model_portfolios/instruments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_model_portfolios_instrument_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"model_portfolios"],[2,[7,"/"],[2,[6,"instruments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/model_portfolios/instruments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_model_portfolios_instruments_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"model_portfolios"],[2,[7,"/"],[2,[6,"instruments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/orders/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_order_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/orders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_orders_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/payments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_payment_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"payments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/payments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_payments_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"payments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/services/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_service_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/services(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_services_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"services"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/updates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_updates_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"updates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/investment_recommendations/:id/approve(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const approve_adviser_investment_recommendation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"investment_recommendations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"approve"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/investment_recommendations/:id/approve(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const approve_customer_investment_recommendation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"investment_recommendations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"approve"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/services/custom/archive(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const archive_adviser_custom_services_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[6,"custom"],[2,[7,"/"],[2,[6,"archive"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/model_portfolios/:id/archive(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const archive_adviser_model_portfolio_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"model_portfolios"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"archive"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/model_portfolios/:id/archive(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const archive_customer_model_portfolio_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"model_portfolios"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"archive"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/model_portfolios/archived(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const archived_adviser_model_portfolios_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"model_portfolios"],[2,[7,"/"],[2,[6,"archived"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /attachments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const attachment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"attachments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /attachments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const attachments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"attachments"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /adviser/model_portfolios/:id/balance(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const balance_adviser_model_portfolio_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"model_portfolios"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"balance"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/become_out(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const become_out_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"become_out"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /customer/payments/best2pay(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const best2pay_customer_payments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"payments"],[2,[7,"/"],[2,[6,"best2pay"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/portfolios/block_portfolio(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const block_portfolio_adviser_customer_portfolios_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[6,"block_portfolio"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /start/personal_information/blocked(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blocked_start_personal_information_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"start"],[2,[7,"/"],[2,[6,"personal_information"],[2,[7,"/"],[2,[6,"blocked"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /broken_garland_ping(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const broken_garland_ping_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"broken_garland_ping"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/month_activities/build(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const build_adviser_cbr_month_activities_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"month_activities"],[2,[7,"/"],[2,[6,"build"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/amocrm/callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const callback_adviser_amocrm_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"amocrm"],[2,[7,"/"],[2,[6,"callback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/contracts/:id/cancel(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancel_adviser_contract_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/brokerage_accounts/brokerage_orders/:id/cancel(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancel_customer_brokerage_accounts_brokerage_order_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"brokerage_accounts"],[2,[7,"/"],[2,[6,"brokerage_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/brokerage_orders/:id/cancel(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancel_customer_brokerage_order_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"brokerage_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/cancel(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancel_user_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /customer/model_portfolios/subscriptions/change_card(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const change_card_customer_subscriptions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"model_portfolios"],[2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[6,"change_card"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /chat_rooms/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chat_room_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"chat_rooms"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /chat_rooms(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chat_rooms_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"chat_rooms"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/panels/clean_attachments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const clean_attachments_admin_panels_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"panels"],[2,[7,"/"],[2,[6,"clean_attachments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/shared_settings/clear_key(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const clear_key_adviser_settings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"shared_settings"],[2,[7,"/"],[2,[6,"clear_key"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/close_warning(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const close_warning_adviser_cbr_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"close_warning"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/contracts/:id/complete(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const complete_adviser_contract_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"complete"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/contracts/:id/complete_new(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const complete_new_adviser_contract_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"complete_new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/contracts/:id/complete_signature(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const complete_signature_customer_contract_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"complete_signature"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/chats/:id/conference(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const conference_adviser_chat_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"chats"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"conference"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/chat/conference(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const conference_customer_chat_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"chat"],[2,[7,"/"],[2,[6,"conference"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:id/confirm(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const confirm_admin_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"confirm"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /start/profile/confirm_email(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const confirm_email_start_profile_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"start"],[2,[7,"/"],[2,[6,"profile"],[2,[7,"/"],[2,[6,"confirm_email"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adaptation/preview/contract(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const contract_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adaptation"],[2,[7,"/"],[2,[6,"preview"],[2,[7,"/"],[2,[6,"contract"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/finance/plans/:id/copy(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const copy_adviser_customer_finance_plan_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"finance"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"copy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/finance_plans/:id/copy(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const copy_adviser_finance_plan_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"finance_plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"copy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/month_activities/create_build(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_build_adviser_cbr_month_activities_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"month_activities"],[2,[7,"/"],[2,[6,"create_build"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/panels/create_refs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_refs_admin_panels_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"panels"],[2,[7,"/"],[2,[6,"create_refs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/model_portfolios/:id/creating(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const creating_customer_model_portfolio_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"model_portfolios"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"creating"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/acceptances(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_acceptances_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"acceptances"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /customer/appeals/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_appeal_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"appeals"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/appeals(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_appeals_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"appeals"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /customer/blocked(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_blocked_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"blocked"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /customer/brokerage_accounts/analytics(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_brokerage_accounts_analytics_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"brokerage_accounts"],[2,[7,"/"],[2,[6,"analytics"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/brokerage_accounts/brokerage_orders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_brokerage_accounts_brokerage_orders_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"brokerage_accounts"],[2,[7,"/"],[2,[6,"brokerage_orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/brokerage_accounts/events(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_brokerage_accounts_events_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"brokerage_accounts"],[2,[7,"/"],[2,[6,"events"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/brokerage_accounts/investment_recommendations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_brokerage_accounts_investment_recommendations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"brokerage_accounts"],[2,[7,"/"],[2,[6,"investment_recommendations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/brokerage_accounts/operations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_brokerage_accounts_operations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"brokerage_accounts"],[2,[7,"/"],[2,[6,"operations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/brokerage_accounts/positions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_brokerage_accounts_positions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"brokerage_accounts"],[2,[7,"/"],[2,[6,"positions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/bug_reports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_bug_reports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"bug_reports"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /customer/chat(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_chat_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"chat"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /customer/chat/messages(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_chat_messages_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"chat"],[2,[7,"/"],[2,[6,"messages"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/consultations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_consultation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"consultations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/consultations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_consultations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"consultations"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /customer/contracts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_contract_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/contracts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_contracts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"contracts"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /customer/credentials/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_credential_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"credentials"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/credentials(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_credentials_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"credentials"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /customer/dashboard/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_dashboard_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_dashboard_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /customer/documents/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/documents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_documents_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"documents"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /customer/finance/plans/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_finance_plan_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"finance"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/finance/plans/:plan_id/goals/:id(.:format)
 * @param {any} plan_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_finance_plan_goal_path = __jsr.r({"plan_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"finance"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"plan_id"],[2,[7,"/"],[2,[6,"goals"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/finance/plans/:plan_id/goals(.:format)
 * @param {any} plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_finance_plan_goals_path = __jsr.r({"plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"finance"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"plan_id"],[2,[7,"/"],[2,[6,"goals"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/finance/plans/:plan_id/recommendations/:id(.:format)
 * @param {any} plan_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_finance_plan_recommendation_path = __jsr.r({"plan_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"finance"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"plan_id"],[2,[7,"/"],[2,[6,"recommendations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/finance/plans/:plan_id/recommendations(.:format)
 * @param {any} plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_finance_plan_recommendations_path = __jsr.r({"plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"finance"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"plan_id"],[2,[7,"/"],[2,[6,"recommendations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/finance/plans/:plan_id/risks/:id(.:format)
 * @param {any} plan_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_finance_plan_risk_path = __jsr.r({"plan_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"finance"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"plan_id"],[2,[7,"/"],[2,[6,"risks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/finance/plans/:plan_id/risks(.:format)
 * @param {any} plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_finance_plan_risks_path = __jsr.r({"plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"finance"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"plan_id"],[2,[7,"/"],[2,[6,"risks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/finance/plans(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_finance_plans_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"finance"],[2,[7,"/"],[2,[6,"plans"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/investment_recommendations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_investment_recommendation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"investment_recommendations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/investment_recommendations/:investment_recommendation_id/investment_instruments/:id(.:format)
 * @param {any} investment_recommendation_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_investment_recommendation_investment_instrument_path = __jsr.r({"investment_recommendation_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"investment_recommendations"],[2,[7,"/"],[2,[3,"investment_recommendation_id"],[2,[7,"/"],[2,[6,"investment_instruments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/investment_recommendations/:investment_recommendation_id/investment_instruments(.:format)
 * @param {any} investment_recommendation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_investment_recommendation_investment_instruments_path = __jsr.r({"investment_recommendation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"investment_recommendations"],[2,[7,"/"],[2,[3,"investment_recommendation_id"],[2,[7,"/"],[2,[6,"investment_instruments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/investment_recommendations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_investment_recommendations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"investment_recommendations"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /customer/model_portfolios/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_model_portfolio_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"model_portfolios"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/model_portfolios/:model_portfolio_id/analytics(.:format)
 * @param {any} model_portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_model_portfolio_analytics_path = __jsr.r({"model_portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"model_portfolios"],[2,[7,"/"],[2,[3,"model_portfolio_id"],[2,[7,"/"],[2,[6,"analytics"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/model_portfolios/:model_portfolio_id/events(.:format)
 * @param {any} model_portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_model_portfolio_events_path = __jsr.r({"model_portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"model_portfolios"],[2,[7,"/"],[2,[3,"model_portfolio_id"],[2,[7,"/"],[2,[6,"events"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/model_portfolios/:model_portfolio_id/investment_recommendations(.:format)
 * @param {any} model_portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_model_portfolio_investment_recommendations_path = __jsr.r({"model_portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"model_portfolios"],[2,[7,"/"],[2,[3,"model_portfolio_id"],[2,[7,"/"],[2,[6,"investment_recommendations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/model_portfolios/:model_portfolio_id/operations(.:format)
 * @param {any} model_portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_model_portfolio_operations_path = __jsr.r({"model_portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"model_portfolios"],[2,[7,"/"],[2,[3,"model_portfolio_id"],[2,[7,"/"],[2,[6,"operations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/model_portfolios/:model_portfolio_id/positions(.:format)
 * @param {any} model_portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_model_portfolio_positions_path = __jsr.r({"model_portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"model_portfolios"],[2,[7,"/"],[2,[3,"model_portfolio_id"],[2,[7,"/"],[2,[6,"positions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/model_portfolios(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_model_portfolios_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"model_portfolios"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /customer/notifications/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_notification_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"notifications"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/notifications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_notifications_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"notifications"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /customer/one_time_recommendations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_one_time_recommendation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"one_time_recommendations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/one_time_recommendations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_one_time_recommendations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"one_time_recommendations"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /customer/payments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_payment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"payments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/payments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_payments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"payments"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /customer/profile(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_profile_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"profile"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /customer/purchase/:pre_order_id/:id(.:format)
 * @param {any} pre_order_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_purchase_path = __jsr.r({"pre_order_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"purchase"],[2,[7,"/"],[2,[3,"pre_order_id"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/purchase(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_purchase_start_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"purchase"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /customer/purchases(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_purchases_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"purchases"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /customer/qualification_attachment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_qualification_attachment_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"qualification_attachment"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /customer/risk_profile(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_risk_profile_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"risk_profile"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /customer(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_root_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /customer/services/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_service_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/services/:service_id/time_slots(.:format)
 * @param {any} service_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_service_time_slots_path = __jsr.r({"service_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[3,"service_id"],[2,[7,"/"],[2,[6,"time_slots"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/model_portfolios/subscriptions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_subscriptions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"model_portfolios"],[2,[7,"/"],[2,[6,"subscriptions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/user_risk_profile(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_user_risk_profile_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"user_risk_profile"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /dadata/suggest_address(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dadata_suggest_address_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"dadata"],[2,[7,"/"],[2,[6,"suggest_address"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /dadata/suggest_full_address(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dadata_suggest_full_address_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"dadata"],[2,[7,"/"],[2,[6,"suggest_full_address"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /dadata/suggest_passport(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dadata_suggest_passport_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"dadata"],[2,[7,"/"],[2,[6,"suggest_passport"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /adviser/profile/delete_avatar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delete_avatar_adviser_profile_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"profile"],[2,[7,"/"],[2,[6,"delete_avatar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin_global/jobs/destroy_all(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_all_admin_global_jobs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin_global"],[2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[6,"destroy_all"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/panels/destroy_cbr(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_cbr_admin_panels_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"panels"],[2,[7,"/"],[2,[6,"destroy_cbr"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_out(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_out"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/docs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const docs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"docs"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /adviser/contracts/document_templates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const document_templates_adviser_contracts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[6,"document_templates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/mailings/:id/duplicate(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const duplicate_adviser_mailing_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"mailings"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"duplicate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/settings/auto_contract_templates/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_auto_contract_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"auto_contract_templates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/bug_reports/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_bug_report_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"bug_reports"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/settings/document_builder_templates/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_document_builder_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"document_builder_templates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin_global/shared/asset_payments/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_global_shared_asset_payment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin_global"],[2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"asset_payments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin_global/shared/brokerage_order_errors/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_global_shared_brokerage_order_error_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin_global"],[2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"brokerage_order_errors"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin_global/shared/exchange_schedules/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_global_shared_exchange_schedule_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin_global"],[2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"exchange_schedules"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/settings/legal_documents/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_legal_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"legal_documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/settings/shared_settings/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_setting_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"shared_settings"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/anketas/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_anketa_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"anketas"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/anketas/:anketa_id/questions/:id/edit(.:format)
 * @param {any} anketa_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_anketa_question_path = __jsr.r({"anketa_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"anketas"],[2,[7,"/"],[2,[3,"anketa_id"],[2,[7,"/"],[2,[6,"questions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/auto_contract_templates/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_auto_contract_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"auto_contract_templates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/bug_reports/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_bug_report_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"bug_reports"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/affiliation_persons/entities/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_cbr_affiliation_persons_entity_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"affiliation_persons"],[2,[7,"/"],[2,[6,"entities"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/affiliation_persons/individuals/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_cbr_affiliation_persons_individual_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"affiliation_persons"],[2,[7,"/"],[2,[6,"individuals"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/affiliation_properties/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_cbr_affiliation_property_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"affiliation_properties"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/assistant_accountant/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_cbr_assistant_accountant_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"assistant_accountant"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/audit_manager/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_cbr_audit_manager_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"audit_manager"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/belonging_person_groups/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_cbr_belonging_person_group_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"belonging_person_groups"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/board_directors_chairman/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_cbr_board_directors_chairman_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"board_directors_chairman"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/board_directors_members/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_cbr_board_directors_member_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"board_directors_members"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/collegial_executives/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_cbr_collegial_executive_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"collegial_executives"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/customers_complaints/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_cbr_customers_complaint_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"customers_complaints"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/division_accountants/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_cbr_division_accountant_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"division_accountants"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/division_heads/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_cbr_division_head_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"division_heads"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/employee_details/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_cbr_employee_details_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"employee_details"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/general_accountant/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_cbr_general_accountant_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"general_accountant"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/individual_info/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_cbr_individual_info_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"individual_info"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/internal_control_manager/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_cbr_internal_control_manager_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"internal_control_manager"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/manipulate_control_head/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_cbr_manipulate_control_head_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"manipulate_control_head"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/month_activities/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_cbr_month_activity_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"month_activities"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/org_info/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_cbr_org_info_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"org_info"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/reporting_entity_controllers/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_cbr_reporting_entity_controller_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"reporting_entity_controllers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/reporting_entity_info/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_cbr_reporting_entity_info_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"reporting_entity_info"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/risk_manager/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_cbr_risk_manager_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"risk_manager"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/separate_divisions_infos/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_cbr_separate_divisions_info_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"separate_divisions_infos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/sole_executive/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_cbr_sole_executive_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"sole_executive"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/sole_executive_assistant/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_cbr_sole_executive_assistant_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"sole_executive_assistant"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/structual_unit_head/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_cbr_structual_unit_head_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"structual_unit_head"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/services/custom/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_custom_service_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[6,"custom"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_customer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/brokerage_accounts/investment_recommendations/:id/edit(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_customer_brokerage_accounts_investment_recommendation_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"brokerage_accounts"],[2,[7,"/"],[2,[6,"investment_recommendations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/brokerage_accounts/investment_recommendations/:investment_recommendation_id/investment_instruments/:id/edit(.:format)
 * @param {any} customer_id
 * @param {any} investment_recommendation_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_customer_brokerage_accounts_investment_recommendation_investment_instrument_path = __jsr.r({"customer_id":{"r":true},"investment_recommendation_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"brokerage_accounts"],[2,[7,"/"],[2,[6,"investment_recommendations"],[2,[7,"/"],[2,[3,"investment_recommendation_id"],[2,[7,"/"],[2,[6,"investment_instruments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/credentials/:id/edit(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_customer_credential_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"credentials"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/finance/plans/:id/edit(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_customer_finance_plan_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"finance"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/finance/plans/:plan_id/goals/:id/edit(.:format)
 * @param {any} customer_id
 * @param {any} plan_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_customer_finance_plan_goal_path = __jsr.r({"customer_id":{"r":true},"plan_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"finance"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"plan_id"],[2,[7,"/"],[2,[6,"goals"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/finance/plans/:plan_id/repayment_strategy/edit(.:format)
 * @param {any} customer_id
 * @param {any} plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_customer_finance_plan_repayment_strategy_path = __jsr.r({"customer_id":{"r":true},"plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"finance"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"plan_id"],[2,[7,"/"],[2,[6,"repayment_strategy"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/investment_recommendations/:id/edit(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_customer_investment_recommendation_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"investment_recommendations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/investment_recommendations/:investment_recommendation_id/investment_instruments/:id/edit(.:format)
 * @param {any} customer_id
 * @param {any} investment_recommendation_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_customer_investment_recommendation_investment_instrument_path = __jsr.r({"customer_id":{"r":true},"investment_recommendation_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"investment_recommendations"],[2,[7,"/"],[2,[3,"investment_recommendation_id"],[2,[7,"/"],[2,[6,"investment_instruments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/documents/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/document_builder_templates/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_document_builder_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"document_builder_templates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/investment_recommendations/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_investment_recommendation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"investment_recommendations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/investment_recommendations/:investment_recommendation_id/investment_instruments/:id/edit(.:format)
 * @param {any} investment_recommendation_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_investment_recommendation_investment_instrument_path = __jsr.r({"investment_recommendation_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"investment_recommendations"],[2,[7,"/"],[2,[3,"investment_recommendation_id"],[2,[7,"/"],[2,[6,"investment_instruments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/legal_documents/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_legal_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"legal_documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/mailings/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_mailing_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"mailings"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/model_portfolios/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_model_portfolio_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"model_portfolios"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/model_portfolios/:model_portfolio_id/operations/:id/edit(.:format)
 * @param {any} model_portfolio_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_model_portfolio_operation_path = __jsr.r({"model_portfolio_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"model_portfolios"],[2,[7,"/"],[2,[3,"model_portfolio_id"],[2,[7,"/"],[2,[6,"operations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/profile/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_profile_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"profile"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/risk_profiles/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_risk_profile_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"risk_profiles"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/risk_profile_setting/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_risk_profile_setting_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"risk_profile_setting"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/services/:service_id/cancellation_options/edit(.:format)
 * @param {any} service_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_service_cancellation_options_path = __jsr.r({"service_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[3,"service_id"],[2,[7,"/"],[2,[6,"cancellation_options"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/shared_settings/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_setting_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"shared_settings"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/trading_platforms/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adviser_trading_platform_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"trading_platforms"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /attachments/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_attachment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"attachments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/contracts/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_customer_contract_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/credentials/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_customer_credential_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"credentials"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/finance/plans/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_customer_finance_plan_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"finance"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/finance/plans/:plan_id/goals/:id/edit(.:format)
 * @param {any} plan_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_customer_finance_plan_goal_path = __jsr.r({"plan_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"finance"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"plan_id"],[2,[7,"/"],[2,[6,"goals"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/investment_recommendations/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_customer_investment_recommendation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"investment_recommendations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/investment_recommendations/:investment_recommendation_id/investment_instruments/:id/edit(.:format)
 * @param {any} investment_recommendation_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_customer_investment_recommendation_investment_instrument_path = __jsr.r({"investment_recommendation_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"investment_recommendations"],[2,[7,"/"],[2,[3,"investment_recommendation_id"],[2,[7,"/"],[2,[6,"investment_instruments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/profile/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_customer_profile_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"profile"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /oauth/applications/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_oauth_application_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"applications"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_rails_conductor_inbound_email_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /start/personal_information/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_start_personal_information_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"start"],[2,[7,"/"],[2,[6,"personal_information"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /start/profile/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_start_profile_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"start"],[2,[7,"/"],[2,[6,"profile"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/password/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /customer/investment_recommendations/:id/execute(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const execute_customer_investment_recommendation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"investment_recommendations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"execute"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/finance/plans/:id/export(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const export_adviser_customer_finance_plan_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"finance"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"export"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/finance/plans/:id/export(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const export_customer_finance_plan_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"finance"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"export"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/finance/plans/:id/fill(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const fill_adviser_customer_finance_plan_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"finance"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"fill"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/finance/plans/:id/fill(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const fill_customer_finance_plan_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"finance"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"fill"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/appeals/:id/finish(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const finish_customer_appeal_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"appeals"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"finish"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/finance/plans/:id/flow(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const flow_adviser_customer_finance_plan_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"finance"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"flow"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/finance/plans/:id/flow(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const flow_customer_finance_plan_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"finance"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"flow"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:id/fns_verify(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const fns_verify_adviser_customer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"fns_verify"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/contracts/free_document_template(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const free_document_template_adviser_contracts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[6,"free_document_template"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/contracts/generate_attachments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const generate_attachments_adviser_contracts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[6,"generate_attachments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/contracts/generate_templates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const generate_templates_adviser_contracts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[6,"generate_templates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/partners/:id/go(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const go_adviser_partner_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"partners"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"go"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /guest(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const guest_root_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"guest"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /adviser(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const head_root_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /adviser/mailings/history(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const history_adviser_mailings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"mailings"],[2,[7,"/"],[2,[6,"history"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/model_portfolios/:model_portfolio_id/analytics/history(.:format)
 * @param {any} model_portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const history_adviser_model_portfolio_analytics_path = __jsr.r({"model_portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"model_portfolios"],[2,[7,"/"],[2,[3,"model_portfolio_id"],[2,[7,"/"],[2,[6,"analytics"],[2,[7,"/"],[2,[6,"history"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/model_portfolios/:model_portfolio_id/analytics/history(.:format)
 * @param {any} model_portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const history_customer_model_portfolio_analytics_path = __jsr.r({"model_portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"model_portfolios"],[2,[7,"/"],[2,[3,"model_portfolio_id"],[2,[7,"/"],[2,[6,"analytics"],[2,[7,"/"],[2,[6,"history"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /ics/:user_id/:token(.:format)
 * @param {any} user_id
 * @param {any} token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ics_path = __jsr.r({"user_id":{"r":true},"token":{"r":true},"format":{"d":"ics"}}, [2,[7,"/"],[2,[6,"ics"],[2,[7,"/"],[2,[3,"user_id"],[2,[7,"/"],[2,[3,"token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin_global/cbr/complaints/import(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const import_admin_global_cbr_complaints_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin_global"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"complaints"],[2,[7,"/"],[2,[6,"import"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin_global/cbr/doc_types/import(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const import_admin_global_cbr_doc_types_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin_global"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"doc_types"],[2,[7,"/"],[2,[6,"import"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin_global/cbr/report_belonging_groups/import(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const import_admin_global_cbr_report_belonging_groups_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin_global"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"report_belonging_groups"],[2,[7,"/"],[2,[6,"import"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin_global/cbr/report_countries/import(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const import_admin_global_cbr_report_countries_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin_global"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"report_countries"],[2,[7,"/"],[2,[6,"import"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin_global/cbr/report_documents/import(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const import_admin_global_cbr_report_documents_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin_global"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"report_documents"],[2,[7,"/"],[2,[6,"import"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin_global/cbr/report_regions/import(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const import_admin_global_cbr_report_regions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin_global"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"report_regions"],[2,[7,"/"],[2,[6,"import"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/purchases/in_process(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const in_process_customer_purchases_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"purchases"],[2,[7,"/"],[2,[6,"in_process"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/finance/plans/:id/inoutcomes(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const inoutcomes_adviser_customer_finance_plan_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"finance"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"inoutcomes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/finance/plans/:id/inoutcomes(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const inoutcomes_customer_finance_plan_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"finance"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"inoutcomes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /intelinvest(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const intelinvest_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"intelinvest"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/customers_archives/investment_recommendations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const investment_recommendations_adviser_customers_archives_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"customers_archives"],[2,[7,"/"],[2,[6,"investment_recommendations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:id/invite(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const invite_admin_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"invite"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:id/invite(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const invite_adviser_customer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"invite"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /legals/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const legal_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"legals"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /locale(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const locale_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"locale"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin_global/tenants/lock_waiting(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lock_waiting_admin_global_tenants_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin_global"],[2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[6,"lock_waiting"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin_global/tenants/locked(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const locked_admin_global_tenants_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin_global"],[2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[6,"locked"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /main/components_v3(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const main_components_v3_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"main"],[2,[7,"/"],[2,[6,"components_v3"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /manifest(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manifest_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manifest"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /customer/payments/mkb_callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mkb_callback_customer_payments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"payments"],[2,[7,"/"],[2,[6,"mkb_callback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/payments/mkb(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mkb_customer_payments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"payments"],[2,[7,"/"],[2,[6,"mkb"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /oauth/authorize/native(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const native_oauth_authorization_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"authorize"],[2,[7,"/"],[2,[6,"native"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/settings/auto_contract_templates/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_auto_contract_template_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"auto_contract_templates"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/settings/document_builder_templates/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_document_builder_template_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"document_builder_templates"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin_global/shared/brokerage_order_errors/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_global_shared_brokerage_order_error_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin_global"],[2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"brokerage_order_errors"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin_global/shared/exchange_schedules/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_global_shared_exchange_schedule_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin_global"],[2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"exchange_schedules"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/settings/legal_documents/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_legal_document_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"legal_documents"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/settings/shared_settings/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_setting_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"shared_settings"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_user_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/amocrm/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_adviser_amocrm_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"amocrm"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/anketas/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_adviser_anketa_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"anketas"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/anketas/:anketa_id/questions/new(.:format)
 * @param {any} anketa_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_adviser_anketa_question_path = __jsr.r({"anketa_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"anketas"],[2,[7,"/"],[2,[3,"anketa_id"],[2,[7,"/"],[2,[6,"questions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/appeals/:appeal_id/response/new(.:format)
 * @param {any} appeal_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_adviser_appeal_response_path = __jsr.r({"appeal_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"appeals"],[2,[7,"/"],[2,[3,"appeal_id"],[2,[7,"/"],[2,[6,"response"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/auto_contract_templates/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_adviser_auto_contract_template_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"auto_contract_templates"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/affiliation_persons/entities/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_adviser_cbr_affiliation_persons_entity_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"affiliation_persons"],[2,[7,"/"],[2,[6,"entities"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/affiliation_persons/individuals/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_adviser_cbr_affiliation_persons_individual_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"affiliation_persons"],[2,[7,"/"],[2,[6,"individuals"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/affiliation_properties/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_adviser_cbr_affiliation_property_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"affiliation_properties"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/belonging_person_groups/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_adviser_cbr_belonging_person_group_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"belonging_person_groups"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/board_directors_members/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_adviser_cbr_board_directors_member_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"board_directors_members"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/collegial_executives/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_adviser_cbr_collegial_executive_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"collegial_executives"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/customers_complaints/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_adviser_cbr_customers_complaint_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"customers_complaints"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/division_accountants/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_adviser_cbr_division_accountant_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"division_accountants"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/division_heads/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_adviser_cbr_division_head_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"division_heads"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/month_activities/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_adviser_cbr_month_activity_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"month_activities"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/reporting_entity_controllers/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_adviser_cbr_reporting_entity_controller_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"reporting_entity_controllers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/reports/irregual/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_adviser_cbr_reports_irregual_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"irregual"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/reports/month10/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_adviser_cbr_reports_month10_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"month10"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/reports/quarterly10/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_adviser_cbr_reports_quarterly10_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"quarterly10"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/reports/quarterly30/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_adviser_cbr_reports_quarterly30_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"quarterly30"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/reports/year10/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_adviser_cbr_reports_year10_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"year10"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/reports/year30/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_adviser_cbr_reports_year30_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"year30"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/reports/year45/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_adviser_cbr_reports_year45_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"year45"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/separate_divisions_infos/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_adviser_cbr_separate_divisions_info_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"separate_divisions_infos"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/contracts/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_adviser_contract_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/services/custom/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_adviser_custom_service_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[6,"custom"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_adviser_customer_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/brokerage_accounts/investment_recommendations/new(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_adviser_customer_brokerage_accounts_investment_recommendation_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"brokerage_accounts"],[2,[7,"/"],[2,[6,"investment_recommendations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/contracts/new(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_adviser_customer_contract_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/credentials/new(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_adviser_customer_credential_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"credentials"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/finance/plans/new(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_adviser_customer_finance_plan_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"finance"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/finance/plans/:plan_id/goals/new(.:format)
 * @param {any} customer_id
 * @param {any} plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_adviser_customer_finance_plan_goal_path = __jsr.r({"customer_id":{"r":true},"plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"finance"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"plan_id"],[2,[7,"/"],[2,[6,"goals"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/finance/plans/:plan_id/repayment_strategy/new(.:format)
 * @param {any} customer_id
 * @param {any} plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_adviser_customer_finance_plan_repayment_strategy_path = __jsr.r({"customer_id":{"r":true},"plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"finance"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"plan_id"],[2,[7,"/"],[2,[6,"repayment_strategy"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/investment_recommendations/new(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_adviser_customer_investment_recommendation_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"investment_recommendations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/investment_recommendations/:investment_recommendation_id/investment_instruments/new(.:format)
 * @param {any} customer_id
 * @param {any} investment_recommendation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_adviser_customer_investment_recommendation_investment_instrument_path = __jsr.r({"customer_id":{"r":true},"investment_recommendation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"investment_recommendations"],[2,[7,"/"],[2,[3,"investment_recommendation_id"],[2,[7,"/"],[2,[6,"investment_instruments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/documents/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_adviser_document_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/document_builder_templates/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_adviser_document_builder_template_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"document_builder_templates"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/finance_plans/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_adviser_finance_plan_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"finance_plans"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/investment_recommendations/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_adviser_investment_recommendation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"investment_recommendations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/investment_recommendations/:investment_recommendation_id/investment_instruments/new(.:format)
 * @param {any} investment_recommendation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_adviser_investment_recommendation_investment_instrument_path = __jsr.r({"investment_recommendation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"investment_recommendations"],[2,[7,"/"],[2,[3,"investment_recommendation_id"],[2,[7,"/"],[2,[6,"investment_instruments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/legal_documents/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_adviser_legal_document_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"legal_documents"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/mailings/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_adviser_mailing_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"mailings"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/model_portfolios/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_adviser_model_portfolio_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"model_portfolios"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/model_portfolios/:model_portfolio_id/operations/new(.:format)
 * @param {any} model_portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_adviser_model_portfolio_operation_path = __jsr.r({"model_portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"model_portfolios"],[2,[7,"/"],[2,[3,"model_portfolio_id"],[2,[7,"/"],[2,[6,"operations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/payments/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_adviser_payment_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"payments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/profile/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_adviser_profile_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"profile"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/risk_profiles/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_adviser_risk_profile_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"risk_profiles"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/risk_profile_setting/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_adviser_risk_profile_setting_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"risk_profile_setting"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/shared_settings/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_adviser_setting_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"shared_settings"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/trading_platforms/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_adviser_trading_platform_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"trading_platforms"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /attachments/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_attachment_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"attachments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /customer/appeals/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_customer_appeal_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"appeals"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/contracts/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_customer_contract_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/credentials/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_customer_credential_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"credentials"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/finance/plans/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_customer_finance_plan_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"finance"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/finance/plans/:plan_id/goals/new(.:format)
 * @param {any} plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_customer_finance_plan_goal_path = __jsr.r({"plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"finance"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"plan_id"],[2,[7,"/"],[2,[6,"goals"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/investment_recommendations/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_customer_investment_recommendation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"investment_recommendations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/investment_recommendations/:investment_recommendation_id/investment_instruments/new(.:format)
 * @param {any} investment_recommendation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_customer_investment_recommendation_investment_instrument_path = __jsr.r({"investment_recommendation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"investment_recommendations"],[2,[7,"/"],[2,[3,"investment_recommendation_id"],[2,[7,"/"],[2,[6,"investment_instruments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/model_portfolios/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_customer_model_portfolio_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"model_portfolios"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/model_portfolios/:model_portfolio_id/operations/new(.:format)
 * @param {any} model_portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_customer_model_portfolio_operation_path = __jsr.r({"model_portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"model_portfolios"],[2,[7,"/"],[2,[3,"model_portfolio_id"],[2,[7,"/"],[2,[6,"operations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/one_time_recommendations/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_customer_one_time_recommendation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"one_time_recommendations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/profile/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_customer_profile_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"profile"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/qualification_attachment/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_customer_qualification_attachment_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"qualification_attachment"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /oauth/applications/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_oauth_application_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"applications"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_source_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/confirmation/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_confirmation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"confirmation"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/invitation/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_invitation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"invitation"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/password/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_up(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_up"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/unlock/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_unlock_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"unlock"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /new_year_modal_ping(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_year_modal_ping_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"new_year_modal_ping"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /customer/model_portfolios/no_risk_profile(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const no_risk_profile_customer_model_portfolios_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"model_portfolios"],[2,[7,"/"],[2,[6,"no_risk_profile"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /oauth/applications/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_application_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"applications"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /oauth/applications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_applications_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"applications"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /oauth/authorize(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_authorization_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"authorize"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /oauth/authorized_applications/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_authorized_application_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"authorized_applications"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /oauth/authorized_applications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_authorized_applications_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"authorized_applications"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /oauth/introspect(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_introspect_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"introspect"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /oauth/revoke(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_revoke_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"revoke"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /oauth/token(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_token_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"token"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /oauth/token/info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_token_info_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"token"],[2,[7,"/"],[2,[6,"info"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/investment_recommendations/:id/orders(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const orders_adviser_customer_investment_recommendation_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"investment_recommendations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/investment_recommendations/:id/orders(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const orders_adviser_investment_recommendation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"investment_recommendations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/investment_recommendations/:id/orders(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const orders_customer_investment_recommendation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"investment_recommendations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/form/org_general_info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const org_general_info_adviser_cbr_form_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"form"],[2,[7,"/"],[2,[6,"org_general_info"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/model_portfolios/:id/overview(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const overview_customer_model_portfolio_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"model_portfolios"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"overview"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /password/phone-instruction(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const password_phone_instruction_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"phone-instruction"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /password/sended(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const password_sended_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"sended"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /adaptation/preview/personal_information(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const personal_information_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adaptation"],[2,[7,"/"],[2,[6,"preview"],[2,[7,"/"],[2,[6,"personal_information"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/finance/plans/:id/possessions(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const possessions_adviser_customer_finance_plan_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"finance"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"possessions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/finance/plans/:id/possessions(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const possessions_customer_finance_plan_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"finance"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"possessions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/user_risk_profile/pre_show(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pre_show_customer_user_risk_profile_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"user_risk_profile"],[2,[7,"/"],[2,[6,"pre_show"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/reports/irregual/prepare(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const prepare_adviser_cbr_reports_irregual_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"irregual"],[2,[7,"/"],[2,[6,"prepare"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/reports/month10/prepare(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const prepare_adviser_cbr_reports_month10_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"month10"],[2,[7,"/"],[2,[6,"prepare"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/reports/quarterly10/prepare(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const prepare_adviser_cbr_reports_quarterly10_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"quarterly10"],[2,[7,"/"],[2,[6,"prepare"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/reports/quarterly30/prepare(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const prepare_adviser_cbr_reports_quarterly30_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"quarterly30"],[2,[7,"/"],[2,[6,"prepare"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/reports/year10/prepare(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const prepare_adviser_cbr_reports_year10_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"year10"],[2,[7,"/"],[2,[6,"prepare"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/reports/year30/prepare(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const prepare_adviser_cbr_reports_year30_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"year30"],[2,[7,"/"],[2,[6,"prepare"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/reports/year45/prepare(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const prepare_adviser_cbr_reports_year45_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"year45"],[2,[7,"/"],[2,[6,"prepare"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/contracts/prepare(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const prepare_adviser_contracts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[6,"prepare"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/contracts/prepare(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const prepare_adviser_customer_contracts_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[6,"prepare"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/reports/irregual/preview(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const preview_adviser_cbr_reports_irregual_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"irregual"],[2,[7,"/"],[2,[6,"preview"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/model_portfolios/:model_portfolio_id/positions/preview(.:format)
 * @param {any} model_portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const preview_adviser_model_portfolio_positions_path = __jsr.r({"model_portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"model_portfolios"],[2,[7,"/"],[2,[3,"model_portfolio_id"],[2,[7,"/"],[2,[6,"positions"],[2,[7,"/"],[2,[6,"preview"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/risk_profile_setting/preview(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const preview_adviser_risk_profile_setting_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"risk_profile_setting"],[2,[7,"/"],[2,[6,"preview"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/model_portfolios/:id/preview(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const preview_customer_model_portfolio_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"model_portfolios"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"preview"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/reporting_entity_info/prof_data(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const prof_data_adviser_cbr_reporting_entity_info_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"reporting_entity_info"],[2,[7,"/"],[2,[6,"prof_data"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adaptation/preview/profile(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const profile_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adaptation"],[2,[7,"/"],[2,[6,"preview"],[2,[7,"/"],[2,[6,"profile"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/finance/plans/:id/protections(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const protections_adviser_customer_finance_plan_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"finance"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"protections"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/finance/plans/:id/protections(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const protections_customer_finance_plan_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"finance"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"protections"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/model_portfolios/:id/publish(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const publish_adviser_model_portfolio_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"model_portfolios"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"publish"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/model_portfolios/:id/publish(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const publish_api_v1_model_portfolio_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"model_portfolios"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"publish"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_path = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_proxy_path = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/:inbound_email_id/incinerate(.:format)
 * @param {any} inbound_email_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_incinerate_path = __jsr.r({"inbound_email_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[3,"inbound_email_id"],[2,[7,"/"],[2,[6,"incinerate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
 * @param {any} inbound_email_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_reroute_path = __jsr.r({"inbound_email_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[3,"inbound_email_id"],[2,[7,"/"],[2,[6,"reroute"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_sources_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/direct_uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_direct_uploads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"direct_uploads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_key/*filename(.:format)
 * @param {any} encoded_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_disk_service_path = __jsr.r({"encoded_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mailgun_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mailgun"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"mime"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_health_check_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/postmark/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_postmark_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"postmark"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/relay/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_relay_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"relay"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/sendgrid/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_sendgrid_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"sendgrid"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_path = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_proxy_path = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/notifications/:id/read(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const read_adviser_notification_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"notifications"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"read"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/notifications/read_all(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const read_all_adviser_notifications_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"notifications"],[2,[7,"/"],[2,[6,"read_all"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/notifications/read_all(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const read_all_customer_notifications_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"notifications"],[2,[7,"/"],[2,[6,"read_all"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/notifications/:id/read(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const read_customer_notification_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"notifications"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"read"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/model_portfolios/subscriptions/reason(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reason_customer_subscriptions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"model_portfolios"],[2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[6,"reason"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/portfolios/refresh(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const refresh_adviser_portfolios_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[6,"refresh"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin_global/jobs/:id/reload(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reload_admin_global_job_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin_global"],[2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"reload"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin_global/jobs/reload_all(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reload_all_admin_global_jobs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin_global"],[2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[6,"reload_all"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /remote-api/incoming-messages-dgtl/ping(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remote_api_incoming_messages_dgtl_ping_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"remote-api"],[2,[7,"/"],[2,[6,"incoming-messages-dgtl"],[2,[7,"/"],[2,[6,"ping"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /remote-api/incoming-messages-dgtl/states(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remote_api_incoming_messages_dgtl_states_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"remote-api"],[2,[7,"/"],[2,[6,"incoming-messages-dgtl"],[2,[7,"/"],[2,[6,"states"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/invitation/remove(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remove_user_invitation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"invitation"],[2,[7,"/"],[2,[6,"remove"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin_global/jobs/:id/requeue(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const requeue_admin_global_job_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin_global"],[2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"requeue"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin_global/jobs/requeue_all(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const requeue_all_admin_global_jobs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin_global"],[2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[6,"requeue_all"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:id/resend_confirmation(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const resend_confirmation_adviser_customer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"resend_confirmation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:id/restore(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const restore_admin_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"restore"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/services/custom/:id/restore(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const restore_adviser_custom_service_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[6,"custom"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"restore"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/model_portfolios/:id/restore(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const restore_adviser_model_portfolio_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"model_portfolios"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"restore"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/model_portfolios/subscriptions/restore(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const restore_customer_subscriptions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"model_portfolios"],[2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[6,"restore"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/brokerage_orders/:id/retry(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const retry_customer_brokerage_order_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"brokerage_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"retry"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/smses/retry(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const retry_users_smses_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"smses"],[2,[7,"/"],[2,[6,"retry"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adaptation/preview/risk_profile(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const risk_profile_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adaptation"],[2,[7,"/"],[2,[6,"preview"],[2,[7,"/"],[2,[6,"risk_profile"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/payments/robokassa_callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const robokassa_callback_customer_payments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"payments"],[2,[7,"/"],[2,[6,"robokassa_callback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /payments/robokassa_callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const robokassa_callback_payments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"payments"],[2,[7,"/"],[2,[6,"robokassa_callback"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /customer/payments/robokassa(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const robokassa_customer_payments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"payments"],[2,[7,"/"],[2,[6,"robokassa"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/chats/room_count_with_unread_msg(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const room_count_with_unread_msg_adviser_chats_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"chats"],[2,[7,"/"],[2,[6,"room_count_with_unread_msg"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const root_path = __jsr.r({}, [7,"/"]);

/**
 * Generates rails route to
 * /swagger
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rswag_api_path = __jsr.r({}, [2,[7,"/"],[6,"swagger"]]);

/**
 * Generates rails route to
 * /admin_global/jobs/run_job(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const run_job_admin_global_jobs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin_global"],[2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[6,"run_job"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/mailings/:id/select_users(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const select_users_adviser_mailing_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"mailings"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"select_users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/mailings/:id/send_to(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_to_adviser_mailing_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"mailings"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"send_to"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/brokerage_accounts/investment_recommendations/:id/send_to_mail(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_to_mail_adviser_customer_brokerage_accounts_investment_recommendation_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"brokerage_accounts"],[2,[7,"/"],[2,[6,"investment_recommendations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"send_to_mail"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/investment_recommendations/:id/send_to_mail(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_to_mail_adviser_customer_investment_recommendation_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"investment_recommendations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"send_to_mail"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/investment_recommendations/:id/send_to_mail(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_to_mail_adviser_investment_recommendation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"investment_recommendations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"send_to_mail"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/additional_info/settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const settings_customer_additional_info_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"additional_info"],[2,[7,"/"],[2,[6,"settings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/investment_instruments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shared_investment_instruments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"investment_instruments"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /customer/appeals/:id/signature(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const signature_customer_appeal_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"appeals"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"signature"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/contracts/:id/signature(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const signature_customer_contract_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"signature"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/documents/:id/signature(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const signature_customer_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"signature"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/user_risk_profile/signature(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const signature_customer_user_risk_profile_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"user_risk_profile"],[2,[7,"/"],[2,[6,"signature"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /smses/signature_modal(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const signature_modal_smses_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"smses"],[2,[7,"/"],[2,[6,"signature_modal"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /adaptation/preview/signin(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const signin_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adaptation"],[2,[7,"/"],[2,[6,"preview"],[2,[7,"/"],[2,[6,"signin"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /smses(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const smses_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"smses"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /start/contract(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const start_contract_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"start"],[2,[7,"/"],[2,[6,"contract"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /start/done(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const start_done_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"start"],[2,[7,"/"],[2,[6,"done"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /start/personal_information(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const start_personal_information_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"start"],[2,[7,"/"],[2,[6,"personal_information"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /start/profile(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const start_profile_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"start"],[2,[7,"/"],[2,[6,"profile"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /start/risk_profile(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const start_risk_profile_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"start"],[2,[7,"/"],[2,[6,"risk_profile"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /adviser/payments/statistic(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const statistic_adviser_payments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"payments"],[2,[7,"/"],[2,[6,"statistic"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /status(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const status_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"status"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:customer_id/credentials/status(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const status_adviser_customer_credentials_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"credentials"],[2,[7,"/"],[2,[6,"status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/credentials/status(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const status_customer_credentials_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"credentials"],[2,[7,"/"],[2,[6,"status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/anketas/:anketa_id/questions/:id/swap(.:format)
 * @param {any} anketa_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const swap_adviser_anketa_question_path = __jsr.r({"anketa_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"anketas"],[2,[7,"/"],[2,[3,"anketa_id"],[2,[7,"/"],[2,[6,"questions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"swap"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/services/custom/:id/switcher(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const switcher_adviser_custom_service_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[6,"custom"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"switcher"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /telegram(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const telegram_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"telegram"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /adviser/profile/telegram_notify(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const telegram_notify_adviser_profile_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"profile"],[2,[7,"/"],[2,[6,"telegram_notify"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /telegram/:token
 * @param {any} token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const telegram_webhook_path = __jsr.r({"token":{"r":true}}, [2,[7,"/"],[2,[6,"telegram"],[2,[7,"/"],[3,"token"]]]]);

/**
 * Generates rails route to
 * /test(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const test_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"test"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/risk_profile_setting/test(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const test_adviser_risk_profile_setting_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"risk_profile_setting"],[2,[7,"/"],[2,[6,"test"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_events/test(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const test_api_events_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_events"],[2,[7,"/"],[2,[6,"test"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api_events/test_new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const test_new_api_events_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_events"],[2,[7,"/"],[2,[6,"test_new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /recede_historical_location(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const turbo_recede_historical_location_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"recede_historical_location"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /refresh_historical_location(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const turbo_refresh_historical_location_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"refresh_historical_location"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /resume_historical_location(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const turbo_resume_historical_location_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"resume_historical_location"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /adviser/notifications/unread(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unread_adviser_notifications_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"notifications"],[2,[7,"/"],[2,[6,"unread"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/notifications/unread(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unread_customer_notifications_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"notifications"],[2,[7,"/"],[2,[6,"unread"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:id/update_adviser(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_adviser_adviser_customer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_adviser"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/cbr/month_activities/update_auto_consults(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_auto_consults_adviser_cbr_month_activities_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"cbr"],[2,[7,"/"],[2,[6,"month_activities"],[2,[7,"/"],[2,[6,"update_auto_consults"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/profile/update_avatar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_avatar_adviser_profile_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"profile"],[2,[7,"/"],[2,[6,"update_avatar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/profile/update_avatar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_avatar_customer_profile_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"profile"],[2,[7,"/"],[2,[6,"update_avatar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:id/update_email(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_email_adviser_customer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_email"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/profile/update_email(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_email_adviser_profile_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"profile"],[2,[7,"/"],[2,[6,"update_email"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/profile/update_email(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_email_customer_profile_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"profile"],[2,[7,"/"],[2,[6,"update_email"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/profile/update_fullname(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_fullname_adviser_profile_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"profile"],[2,[7,"/"],[2,[6,"update_fullname"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/profile/update_fullname(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_fullname_customer_profile_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"profile"],[2,[7,"/"],[2,[6,"update_fullname"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:id/update_investor_type(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_investor_type_adviser_customer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_investor_type"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/profile/update_password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_password_adviser_profile_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"profile"],[2,[7,"/"],[2,[6,"update_password"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/profile/update_password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_password_customer_profile_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"profile"],[2,[7,"/"],[2,[6,"update_password"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:id/update_phone(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_phone_adviser_customer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_phone"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/profile/update_phone(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_phone_adviser_profile_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"profile"],[2,[7,"/"],[2,[6,"update_phone"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /customer/profile/update_phone(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_phone_customer_profile_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"profile"],[2,[7,"/"],[2,[6,"update_phone"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_token(.:format)
 * @param {any} encoded_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_rails_disk_service_path = __jsr.r({"encoded_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /user_avatars/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_avatar_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"user_avatars"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/confirmation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_confirmation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"confirmation"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/invitation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_invitation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"invitation"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/unlock(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_unlock_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"unlock"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_up(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_sign_up_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_up"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/smses(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_smses_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"smses"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/settings/document_builder_templates/variables(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const variables_admin_document_builder_templates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"document_builder_templates"],[2,[7,"/"],[2,[6,"variables"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/settings/document_builder_templates/variables(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const variables_adviser_document_builder_templates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"document_builder_templates"],[2,[7,"/"],[2,[6,"variables"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /adviser/customers/:id/verified(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const verified_adviser_customer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"adviser"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"verified"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /smses/verify(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const verify_smses_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"smses"],[2,[7,"/"],[2,[6,"verify"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/smses/verify(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const verify_users_smses_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"smses"],[2,[7,"/"],[2,[6,"verify"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /wiki_auth(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wiki_auth_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"wiki_auth"],[1,[2,[8,"."],[3,"format"]]]]]);


