<template>
  <div class="so-loader" :class="[type, size]" v-show="displayLoader">
    <div v-if="size === 's-size'">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8"
          stroke="url(#s-gradient)"
          stroke-width="2"
          stroke-linecap="round"
        />

        <defs>
          <linearGradient id="s-gradient" x1="8.29167" y1="15.2917" x2="0.416666" y2="12.9583" gradientUnits="userSpaceOnUse">
            <stop stop-color="var(--loader_color)" />
            <stop offset="1" stop-color="var(--loader_color)" stop-opacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </div>

    <div v-else-if="size === 'm-size'">
      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14 26C20.6274 26 26 20.6274 26 14C26 7.37258 20.6274 2 14 2C7.37258 2 2 7.37258 2 14"
          stroke="url(#m-gradient)"
          stroke-width="2.5"
          stroke-linecap="round"
        />

        <defs>
          <linearGradient id="m-gradient" x1="14.5" y1="26.5" x2="0.999999" y2="22.5" gradientUnits="userSpaceOnUse">
            <stop stop-color="var(--loader_color)" />
            <stop offset="1" stop-color="var(--loader_color)" stop-opacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      type: { type: String, default: 'default' },
      size: { type: String, default: 'm-size' },
      reactToAjax: { type: Boolean, default: false }
    },

    emits: ['running'],

    data () {
      return {
        requestInProcess: false
      }
    },

    computed: {
      displayLoader () {
        return this.reactToAjax ? this.requestInProcess : true
      }
    },

    mounted () {
      if (this.reactToAjax) {
        document.addEventListener('ajax:before', (event) => {
          const el = event.target
          if (el) {
            this.requestInProcess = true
            this.$emit('running', this.requestInProcess)
          }
        })

        document.addEventListener('ajax:complete', (event) => {
          const el = event.target
          if (el) {
            this.requestInProcess = false
            this.$emit('running', this.requestInProcess)
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @keyframes rotation {
    0% {
      transform:rotate(0deg);
    }
    100% {
      transform:rotate(360deg);
    }
  }

  .so-loader {
    height: var(--size);
    width: var(--size);
    line-height: var(--size);
    animation-name: rotation;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    // types
    &.default {
      --loader_color: var(--ui-color);
    }

    &.white {
      --loader_color: var(--white);
    }

    &.black {
      --loader_color: var(--black);
    }

    // sizes
    &.s-size {
      --size: 16px;
    }

    &.m-size {
      --size: 28px;
    }
  }
</style>
