import lodashGet from 'lodash/get'
import last from 'lodash/last'

export default {
  props: {
    modelValue: { default: '' },
    errors: { type: Array, default: () => [] },
    label: { type: String },
    tooltip: { type: String },
    allowClean: { type: Boolean, default: true },
    name: { type: String, required: true },
    blur: { type: Function },
    helperText: { type: String },
    withLoader: { type: Boolean, default: false },
    useStore: { type: Boolean, default: true }
  },

  emits: ['changed'],

  data () {
    return {
      path: null,
      field: null,
      localValue: this.modelValue,
      localErrors: this.errors,
      inputHoverStyle: false
    }
  },

  computed: {
    attrs () {
      return this.$attrs
    },

    disabled () {
      const hasDisabled = this.attrs && this.attrs.disabled
      const hasReadOnly = this.attrs && this.attrs.readonly
      return (hasDisabled && !!this.attrs.disabled) || (hasReadOnly && !!this.attrs.readonly)
    },

    isCleared () {
      return (this.allowClean === false ? this.allowClean : !!this.cmpValue && !this.disabled) || null
    },

    cmpValue: {
      get () {
        if (this.useStore) {
          return lodashGet(this.$store.state, this.path.join('.'))
        }

        return this.localValue
      },
      set (value) {
        if (this.useStore) {
          this.$store.commit('SET_STATE', {
            path: this.path,
            value
          })
        }

        this.localValue = value
        this.$emit('changed', this.localValue)
      }
    },

    cmpErrors: {
      get () {
        if (this.useStore) {
          return lodashGet(this.$store.state.errors, this.path.join('.'), [])
        }

        return this.localErrors
      },
      set (value) {
        if (this.useStore) { this.$store.commit('SET_ERRORS', { path: this.path, value }) }
        this.localErrors = value
      }
    }
  },

  watch: {
    modelValue (newVal) {
      this.localValue = newVal
      if (!newVal) {
        this.cmpValue = newVal
      }
    },

    errors (newVal) {
      if (this.useStore) {
        this.path = this.name.match(/([a-zA-Z0-9_])+|(\[\])/g);
        this.$store.commit('SET_ERRORS', { path: this.path, value: newVal });
      }
      this.localErrors = newVal
    }
  },

  methods: {
    getBlur (data, event) {
      if (!this.blur) { return () => {} }

      return this.blur(data, event)
    },

    hoverInput () {
      this.inputHoverStyle = true
    },

    unhoverInput () {
      this.inputHoverStyle = false
    },

    clickByLabel () {
      this.cleanErrors()
      this.focused()
    },

    cleanErrors () {
      this.cmpErrors = []
    }
  },

  created () {
    if (this.useStore) {
      this.path = this.name.match(/([a-zA-Z0-9_])+|(\[\])/g);

      this.$store.commit('SET_STATE', { path: this.path, value: this.modelValue });
      this.$store.commit('SET_ERRORS', { path: this.path, value: this.errors });

      if (last(this.path) === '[]') this.path.pop()
    }
  }
}
