<template>
  <div class="input-box" :class="{'with-error': withErrors, cleared: isCleared}">
    <slot />

    <ul class="errors" v-if="withErrors">
      <li class="subtitle-txt" v-for="(error, index) in errors" :key="index">
        {{ error }}
      </li>
    </ul>

    <div class="input-helper-text" v-else-if="withHelperText">
      <span class="subtitle-txt"> {{ helperText }} </span>
    </div>
  </div>
</template>

<script>
  import isEmpty from 'lodash/isEmpty'

  export default {
    props: {
      errors: { type: Array, default: () => [] },
      clean: { type: Function, default: null },
      isCleared: { type: Boolean, default: false },
      helperText: { type: String, default: null }
    },

    computed: {
      withErrors () {
        return !isEmpty(this.errors)
      },

      withHelperText () {
        return !isEmpty(this.helperText)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .input-box {
    position: relative;

    &.with-error {
      input:not(:focus) {
        border-color: var(--red);
      }
    }

    .errors {
      list-style: none;
      margin: 0;
      padding-left: 0;

      li {
        color: var(--red);
        margin: 5px 0;
        word-break: break-word
      }
    }

    .input-helper-text {
      color: var(--dark-grey);
      line-height: 21px;
    }
  }
</style>

<style lang="scss">
 .input-box {
    .loader-wrapper, .btn-additional {
      height: 24px;
      width: 24px;
      min-width: 24px;
      max-width: 24px;
      position: absolute;
      top: 19px;
      right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      z-index: 5;
      user-select: none;
    }

    .btn-additional {
      button, span {
        background: transparent;
        font-size: 20px;
        padding: 0;
        border: unset;
        color: var(--black);
        margin: 0;
        height: inherit;
        width: inherit;
        min-width: 0;
        line-height: 0;
        cursor: pointer;
      }

      i {
        color: var(--black);
        cursor: pointer;

        &.secondary {
          color: var(--dark-grey);
        }
      }

      &.password {
        background: unset;

        i {
          margin: 0;
        }
      }
    }

    input:read-only, input:disabled {
      & ~ .btn-additional {
        color: var(--grey);
        cursor: default;
      }
    }
  }
</style>
