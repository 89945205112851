const callSuccessToastr = function () {
  const toastr = document.createElement('div')
  toastr.innerHTML = `
    <div class="success-toastr">
      <div class="success-toastr__icon" />
    </div>
  `

  document.body.appendChild(toastr)
  setTimeout(() => { toastr.remove() }, 1700)
}

export default {
  install (app) {
    app.config.globalProperties.$successToastr = callSuccessToastr
  }
}

window.callSuccessToastr = callSuccessToastr
