<template>
  <div
    class="app-tooltip"
    v-bind="$attrs"
    ref="tooltip"
  >
    <div
      class="app-tooltip__icon"
      @click.stop="clickIcon"
      @mouseover="hoverIcon($event)"
      @mouseout="unhoverIcon()"
    >
      <i class="material-icons">{{ icon }}</i>
    </div>

    <app-mobile-tooltip
      :state="isMobile && showMobileTooltip"
      @close="closeMobileTooltip"
    >
      <div class="body-txt">{{ text }}</div>
    </app-mobile-tooltip>
  </div>
</template>

<script>
  import isMobile from 'is-mobile'
  import typograf from '../../library/typograf'

  export default {
    props: {
      text: { type: String, required: true },
      icon: { type: String, default: 'info' },
      compact: { type: Boolean, default: false }
    },

    data () {
      return {
        showMobileTooltip: false,
        tooltipClassList: []
      }
    },

    computed: {
      isMobile () {
        return isMobile()
      }
    },

    mounted () {
      this.tooltipClassList = this.$refs.tooltip.classList.value.split(' ')
    },

    methods: {
      clickIcon (e) {
        e.preventDefault()
        if (isMobile()) { this.showMobileTooltip = true }
      },

      closeMobileTooltip () {
        this.showMobileTooltip = false
      },

      unhoverIcon () {
        this.removeDesctopTooltipEl()
      },

      removeDesctopTooltipEl () {
        document.querySelector('.app-tooltip__window')?.remove()
        window.removeEventListener('scroll', this.handleScroll)
      },

      hoverIcon (event) {
        const tooltipOffset = event.target.getBoundingClientRect()
        window.addEventListener('scroll', this.handleScroll)

        if (!isMobile()) {
          this.createDesctopTooltipEl(tooltipOffset)
        }
      },

      createDesctopTooltipEl (tooltipOffset) {
        const tooltipOffsetY = tooltipOffset.y + (tooltipOffset.height / 2)
        const tooltipOffsetX = tooltipOffset.x + (tooltipOffset.width / 2)
        const positionCoords = this.tooltipPositionCalculate(tooltipOffset)

        const elem = document.createElement('div')

        elem.classList.add(
          'app-tooltip__window',
          this.compact ? 'subtitle-txt' : 'body-sm-txt',
          ...this.tooltipClassList
        )
        elem.classList.remove('app-tooltip')

        elem.style.top = `${tooltipOffsetY}px`
        elem.style.left = `${tooltipOffsetX}px`
        elem.style.transform = `translate(${positionCoords.x}, ${positionCoords.y})`

        elem.innerHTML = `
          <div>${typograf(this.text)}</div>
        `
        document.body.appendChild(elem)

        return elem
      },

      tooltipPositionCalculate (tooltipOffset) {
        const mainCenter = this.mainCenterCalculate()
        const x = tooltipOffset.x >= mainCenter.centerX ? '-102%' : '2%'
        const y = tooltipOffset.y >= mainCenter.centerY ? '-110%' : '10%'

        return { x, y }
      },

      mainCenterCalculate () {
        const mainElement = document.querySelector('body')
        const cachedStyle = getComputedStyle(mainElement)

        const centerX = cachedStyle.getPropertyValue('--center-y') || this.calculateCenterScreenX(mainElement)
        const centerY = cachedStyle.getPropertyValue('--center-x') || this.calculateCenterScreenY()

        mainElement.style.setProperty('--center-y', centerX);
        mainElement.style.setProperty('--center-x', centerY);

        return { centerX, centerY }
      },

      calculateCenterScreenX (elem) {
        return elem.offsetLeft + (elem.offsetWidth + 360) / 2
      },

      calculateCenterScreenY () {
        return window.screen.height / 2
      },

      handleScroll () {
        this.removeDesctopTooltipEl()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .app-tooltip {
    width: fit-content;
    position: relative;
    display: flex;
    align-items: center;
    cursor: help;

    &:hover {
      color: var(--dark-grey);

      i.material-icons {
        color: var(--dark-grey);
      }
    }

    &__icon {
      display: flex;
      align-items: center;
      width: 20px;
      height: 20px;
      color: var(--grey);
      user-select: none;
      transition: .2s;

      i.material-icons {
        color: var(--grey);
        font-size: 20px;
      }

      &:hover {
        color: var(--dark-grey) !important;
        cursor: help;

        i.material-icons {
          color: var(--dark-grey) !important;
        }
      }
    }
  }

  .app-tooltip {
    &.inline {
      display: inline-block;
      margin-left: 5px;
      margin-right: 5px;
      padding: 0;
      vertical-align: middle;
      line-height: 100%;

      .app-tooltip__icon {
        height: 100%;
      }

      i.material-icons { line-height: inherit; }
    }

    &.small {
      vertical-align: middle;

      .app-tooltip__icon {
        width: 15px;
        height: 100%;
      }

      i.material-icons {
        color: var(--grey);
        font-size: 15px;
      }
    }

    &.on-primary {
      .app-tooltip__icon {
        i.material-icons {
          color: var(--primary-color-extralight);
        }

        &:hover {
          i.material-icons {
            color: var(--white);
          }
        }
      }
    }

    &.on-ui {
      .app-tooltip__icon {
        i.material-icons {
          opacity: 0.7;
          color: var(--on-ui-color) !important;
        }

        &:hover {
          i.material-icons {
            opacity: 1;
            color: var(--on-ui-color) !important;
          }
        }
      }
    }
  }

</style>

<style lang="scss">
  .app-tooltip__window {
    width: fit-content;
    max-width: 320px;
    padding: 10px 10px 10px;
    border: 1px solid var(--grey);
    box-sizing: border-box;
    box-shadow: 0 4px 19px rgba(0, 0, 0, 0.1);
    position: fixed;
    border-radius: 10px;
    background-color: var(--white);
    color: var(--black);
    z-index: 1000;
    user-select: none;
    text-align: left;
    white-space: normal;
  }

  // positions
  .app-tooltip__window {
    &.top-center {
      transform: translate(-50%, -120%) !important;
    }

    &.bottom-center {
      transform: translate(-50%, 15%) !important;
    }

    &.right-top {
      transform: translate(3%, -110%) !important;
    }

    &.right-center {
      transform: translate(5%, -50%) !important;
    }

    &.right-bottom {
      transform: translate(3%, 10%) !important;
    }

    &.left-top {
      transform: translate(-103%, -110%) !important;
    }

    &.left-center {
      transform: translate(-105%, -50%) !important;
    }

    &.left-bottom {
      transform: translate(-103%, 10%) !important;
    }
  }
</style>
